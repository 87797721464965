<template>
  <div class="list">
    <div class="top">
      <el-menu
        default-active="1"
        mode="horizontal"
        active-text-color="#545c64">
        <el-menu-item index="1">订单管理</el-menu-item>
      </el-menu>
      <div class="search">
        <el-form ref="form" :model="form" inline>
          <el-form-item>
            <el-input v-model="form.name" placeholder="搜索" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain @click="onSubmit">查询</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="handleAdd">创建模板</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-table
      :data="tableData"
      v-loading="loading"
      style="width: 100%; margin-top:20px"
    >
      <el-table-column
        prop="name"
        label="模板名称"
        width="400">
      </el-table-column>
      <el-table-column
        prop="url"
        label="模板图片"
        width="300">
        <template slot-scope="scope">
          <el-image 
            :src="scope.row.url" 
            fit="fill" 
            :preview-src-list="[scope.row.url]"
            style="width: 40px; height: 40px">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="code"
        label="模板ID"
        width="350">
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="发布时间"
        min-width="200">
      </el-table-column>
      <el-table-column
        prop="updated_at"
        label="修改时间"
        min-width="200">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="280">
        <template slot-scope="scope">
          <el-button  type="info" size="small" @click="goBlock(scope.row)">编辑模板</el-button>
          <el-button  type="warning" size="small" @click="handleEdit(scope.row)">编辑</el-button>
          <el-popconfirm
            icon="el-icon-info"
            icon-color="red"
            title="确定要删除此项？"
            @confirm="delConfirm(scope.row)"
          >
            <el-button type="danger" size="small" slot="reference" style="margin-left:10px">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: right;margin: 10px 0"
      background
      :current-page.sync="form.page"
      :page-size="form.size"
      :total="total"
      layout="total, prev, pager, next"
       @current-change="handleCurrentChange"
      >
    </el-pagination>
    <el-dialog
      :title="addForm.id ? '编辑模板' : '创建模板'"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form :model="addForm" label-position="top" :rules="rules" ref="ruleForm">
        <el-form-item label="模板名称" prop="name">
          <el-input v-model="addForm.name" autocomplete="off" clearable></el-input>
        </el-form-item>
        <el-form-item label="模板图片" prop="url" v-if="!addForm.id">
          <el-upload
            class="uploader"
            :action="`${$api.bsae_api}/common/upload`"
            :headers="headers"
            :show-file-list="false"
            :on-success="fileSuccess">
            <img v-if="addForm.url" :src="addForm.url" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:"",
  data(){
    return {
      dialogVisible:false,
      addForm:{
        name:'',
        url:''
      },
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'change' }],
        url: [{ required: true, message: '请上传模板图片', trigger: 'change' }]
      },
      form:{
        page:1,
        size:10,
        name:''
      },
      loading:true,
      total:0,
      tableData: [],
      headers:{
        Authorization: window.localStorage.getItem('orc_token')
      }
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      this.loading = true
      this.$api.getTempList(this.form).then(res => {
        if(res.status == 200){
          this.total = res.data.count
          this.tableData = res.data.lists
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    onSubmit(){
      this.form.page = 1;
      this.getList()
    },
    handleCurrentChange(val){
      this.form.page = val;
      this.getList()
    },
    fileSuccess(response,file){
      if(response.status == 200){
        this.addForm.url = response.data.base_url+ '/' + response.data.file
      }
    },
    handleAdd(){
      this.addForm = {
        name: '',
        url: ''
      }
      if(this.$refs['ruleForm']){
        this.$refs['ruleForm'].resetFields()
      }
      this.dialogVisible = true;
    },
    addConfirm(){
      let _this = this;
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.addForm.id){
            _this.$api.setTempEdit(_this.addForm).then(res => {
              if(res.status == 200){
                _this.dialogVisible = false
                _this.$message({
                  message: res.msg,
                  type: 'success'
                });
                _this.getList();
              }
            })
          }else{
            _this.$api.setTempAdd(_this.addForm).then(res => {
              if(res.status == 200){
                _this.dialogVisible = false
                _this.$message({
                  message: res.msg,
                  type: 'success'
                });
                _this.getList();
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleEdit(row){
      this.addForm = {
        id:row.id,
        name: row.name,
        url: row.url
      }
      if(this.$refs['ruleForm']){
        this.$refs['ruleForm'].clearValidate()
      }
      this.dialogVisible = true;
    },
    delConfirm(row){
      this.$api.setTempDel({id:row.id}).then(res => {
        if(res.status == 200){
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.getList();
        }
      })
    },
    goBlock(row){
      this.$router.push({
        path:'/index/template/block',
        query:{
          id:row.id,
          url:row.url,
          width: row.width,
          height: row.height
        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
.top{
  margin-top: -20px;
  position: relative;
  .search{
    padding: 10px 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.uploader{
  width: 100px;
  height: 100px;
  border: 1px dashed #c0ccda;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  .avatar-uploader-icon{
    font-size: 30px;
    color: #c0ccda;
  }
  img{
    max-width: 100%;
    max-height: 100%;
  }
}
.list-img{
  width: 40px;
  height: 40px;
  font-size: 0;
}
</style>